<template>
  <div class="table-responsive">
    <!-- Campo de Búsqueda -->
    <div class="mb-3 col-12 d-flex justify-content-between align-items-center" v-if="data.length > 0">
      <!-- Botón alineado a la izquierda -->
      <b-form-select class="col-1" id="sucursal" v-model="itemsPerPage" value-field="value" text-field="value" :options="listPerPage"></b-form-select>

      <!-- Input y botón alineados a la derecha -->
      <div class="d-flex align-items-center">
        <input type="text" v-model="searchQuery" placeholder="Buscar..." class="form-control me-2" />
        <button @click="searchQuery = ''" class="btn btn-light ml-2">x</button>
      </div>
    </div>
    <!-- Tabla con Encabezados y Datos Paginados -->
    <table class="table table-striped table-bordered">
      <thead>
        <tr>
          <th v-for="(header, index) in headers" :key="index">{{ header.header }}</th>
          <th>Opciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, rowIndex) in paginatedData" :key="rowIndex">
          <td v-for="(header, colIndex) in headers" :key="`${rowIndex}-${colIndex}`">
            {{ formatValue(item[header.key], header.key) }}
          </td>
          <td class="d-flex justify-content-around">
            <b-button v-b-tooltip.hover :title="titleButton1" v-if="button1 != null" @click.prevent="actions1(item.id)" :variant="color" class="mr-2">
              <li :class="button1" style="color: white !important"></li>
            </b-button>
            <b-button v-b-tooltip.hover :title="titleButton2" v-if="actions2 != null" @click="actions2(item.id)" :variant="color2">
              <li :class="button2" style="color: white !important"></li>
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Componente de Paginación -->
    <pagination class="mt-2" v-if="data.length > 0" :total="filteredData.length" :per-page="itemsPerPage" v-model="currentPage" />
  </div>
</template>

<script>
// import { search } from "core-js/fn/symbol";
// import { search } from "core-js/fn/symbol";
import Pagination from "./PaginadorComponente.vue";

export default {
  components: { Pagination },
  props: {
    data: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
    actions1: {
      type: Function,
      required: false,
    },
    button1: {
      type: String,
      required: false,
    },
    titleButton1: {
      required: false,
    },
    color: {
      required: false,
      default: "success",
    },
    actions2: {
      type: Function,
      required: false,
    },
    button2: {
      type: String,
      required: false,
    },
    titleButton2: {
      required: false,
    },
    color2: {
      required: false,
      default: "success",
    },
  },
  data() {
    return {
      listPerPage: [{ value: 5 }, { value: 10 }, { value: 20 }, { value: 50 }, { value: 100 }],
      currentPage: 1,
      searchQuery: "", // Término de búsqueda
    };
  },
  computed: {
    filteredData() {
      return this.data.filter((item) => Object.values(item).some((value) => String(value).toLowerCase().includes(this.searchQuery.toLowerCase())));
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredData.slice(start, end);
    },
  },
  watch: {
    itemsPerPage() {
      this.currentPage = 1;
    },
    searchQuery() {
      this.currentPage = 1;
    },
    filteredData() {
      if (this.currentPage > Math.ceil(this.filteredData.length / this.itemsPerPage)) {
        this.currentPage = 1;
      }
    },
  },
  methods: {
    formatValue(value, key) {
      // Para depuración, mostrar los valores en la consola
      // console.log(`Campo: ${key}, Valor: ${value}`);

      // Formatear si el key es "fecha"
      if (key.toLowerCase() === "fecha" || key.toLowerCase() === "fechaaccion") {
        const date = new Date(value);
        return isNaN(date) ? value : `${String(date.getDate()).padStart(2, "0")}-${String(date.getMonth() + 1).padStart(2, "0")}-${date.getFullYear()}`;
      }
      if (key.toLowerCase() === "porcentaje") {
        return `${(value * 100).toFixed(2) + "%"}`;
      }
      // Agregar signo de dólar si el key es "valor" o "valorPrestacion"
      if (
        key.toLowerCase() === "valor" ||
        key.toLowerCase() === "valorprestacion" ||
        key.toLowerCase() === "valorprestacionsub" ||
        key.toLowerCase() === "totales" ||
        key.toLowerCase() === "prestacion" ||
        key.toLowerCase() === "porcentaje" ||
        key.toLowerCase() === "inicios" ||
        key.toLowerCase() === "packsjulio" ||
        key.toLowerCase() === "notadebito" ||
        key.toLowerCase() === "valorprestacionajustado" ||
        key.toLowerCase() === "valortratamientoajustado" ||
        key.toLowerCase() === "valormenossubvencion"
      ) {
        console.log("valoryyy-", key, value);
        return value != null ? value.toLocaleString("es-CO", { style: "currency", currency: "COP" }) : 0;
      }

      // Devolver el valor sin cambios para todos los demás campos
      return value !== undefined && value !== null ? value : "-";
    },
  },
};
</script>

<style scoped>
.table-responsive {
  margin-top: 1rem;
}
</style>
